<template>
  <h1 class="text-center">Authentications</h1>
</template>

<script>
export default {
  name: "Authentications"
}
</script>

<style scoped>

</style>